import React from "react"

import Layout from "../../components/layout"
import {
  Banner,
  BannerStyle,
  Cards,
  ContainerCustom,
  PaddingWrapper,
  TitleH2Center,
} from "@igloonet-web/shared-ui"

import { VYVOJ_REFERENCES_FULL } from "../../model/reference-vyvoj-references-full"

const ReferenceVyvoj: React.FC = () => {
  return (
    <Layout>
      <Banner
        style={BannerStyle.Reference}
        orangeHeading="Vývojové "
        blackHeading="reference"
      />

      <ContainerCustom>
        <PaddingWrapper>
          <TitleH2Center>Naši klienti</TitleH2Center>
          <p className={"text-center mb-5 w-75 mx-auto"}>
            Web či e‑shop rozvíjíme dlouhodobě. Klientům jsme k&nbsp;ruce od
            prvních nápadů přes průzkumy a&nbsp;návrhy až k&nbsp;samotnému
            řešení. A&nbsp;tím práce na nejlepších projektech teprve začíná,
            stále je co vylepšovat.
          </p>

          <Cards heading="Vývoj webů" data={VYVOJ_REFERENCES_FULL} />
        </PaddingWrapper>
      </ContainerCustom>
    </Layout>
  )
}

export default ReferenceVyvoj
