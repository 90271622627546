import LogoDomyDnes from "../images/reference/domy-dnes/logo-domydnes.png"
import LogoPanvicky from "../images/reference/panvicky/logo-panvicky.png"
import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoRoyalCanin from "../images/reference/royal-canin/logo-rc.png"
import BGPH from "../images/reference/bg-ph.png"
import logoldf from "../images/reference/ldf/logoldf.svg"
import logomd from "../images/reference/moravske-drevostavby/logo-moravskedrevostavby.png"
import BGLDF from "../images/reference/bg-ldf.png"
import BGMD from "../images/reference/bg-md.png"
import BGSZ from "../images/reference/bg-sz.png"
import BGDD from "../images/reference/bg-dd.png"
import BGAktin from "../images/reference/bg-aktin.png"
import BGStorge from "../images/reference/bg-storge.png"
import LogoAktin from "../images/reference/aktin/logo-aktin.png"
import LogoStorge from "../images/reference/storge/logo-storge.png"
import LogoFenstar from "../images/reference/fenstar/logo.svg"
import BGRC from "../images/reference/bg-rc.png"
import BGDK from "../images/reference/bg-dk.png"
import BGFenstar from "../images/reference/bg-fenstar.png"
import BGPans from "../images/reference/bg-pans.png"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"
import LogoCCRJM from "../images/reference/ccrjm/logo-convention.png"
import BGCCRJM from "../images/reference/bg-ccrjm.png"
import logomupo from "../images/reference/mupo/logo.png"
import BGMupo from "../images/reference/bg-mupo.png"
import LogoGlobalIT from "../images/reference/global-it/logo.jpg"
import BGGLobalIT from "../images/reference/bg-global-it.png"

export const VYVOJ_REFERENCES_FULL = [
  {
    src: BGMupo,
    alt: "",
    logo: logomupo,
    logoAlt: "logo společnosti MÜPO",
    title: "MÜPO",
    link: "reference/mupo",
    smallerImg: true,
  },
  {
    src: BGCCRJM,
    alt: "",
    logo: LogoCCRJM,
    logoAlt: "logo Brno Convention Bureau",
    title: "CCRJM",
    link: "reference/aplikace-pro-centralu-cestovniho-ruchu-jm",
    smallerImg: true,
  },
  {
    src: BGRC,
    alt: "",
    logo: LogoRoyalCanin,
    logoAlt: "logo společnosti Royal Canin",
    title: "Royal Canin",
    link: "reference/royal-canin",
  },
  {
    src: BGGLobalIT,
    alt: "",
    logo: LogoGlobalIT,
    logoAlt: "logo společnosti Global IT Center",
    title: "Global IT Center",
    link: "reference/global-it",
  },
  {
    src: BGDK,
    alt: "",
    logo: LogoDoktorKladivo,
    logoAlt: "logo e-shopu doktor-kladivo.cz",
    title: "DoktorKladivo.cz",
    link: "reference/doktor-kladivo",
  },
  {
    src: BGFenstar,
    alt: "",
    logo: LogoFenstar,
    logoAlt: "logo společnosti Fenstar",
    title: "FenStar",
    link: "reference/fenstar",
  },
  {
    src: BGStorge,
    alt: "",
    logo: LogoStorge,
    logoAlt: "logo společnosti Storge",
    title: "Storge",
    link: "reference/storge",
  },
  {
    src: BGSZ,
    alt: "",
    logo: LogoSZ,
    logoAlt: "logo Snowboard Zezula",
    title: "SNOWBOARD ZEZULA",
    link: "reference/snowboard-zezula",
  },
  {
    src: BGPH,
    alt: "",
    logo: LogoPlaneta,
    logoAlt: "logo společnosti Planetaher.cz",
    title: "Planetaher.cz",
    link: "reference/planetaher",
  },
  {
    src: BGDD,
    alt: "",
    logo: LogoDomyDnes,
    logoAlt: "logo společnosti Domy D.N.E.S",
    title: "Domy D.N.E.S",
    link: "reference/domy-dnes",
  },
  {
    src: BGMD,
    alt: "",
    logo: logomd,
    logoAlt: "logo společnosti Moravské Dřevostavby",
    title: "MORAVSKÉ DŘEVOSTAVBY",
    link: "reference/moravske-drevostavby",
  },
  {
    src: BGAktin,
    alt: "",
    logo: LogoAktin,
    logoAlt: "logo společnosti Aktin",
    title: "Aktin",
    link: "reference/aktin",
  },
  {
    src: BGPans,
    alt: "",
    logo: LogoPanvicky,
    logoAlt: "logo e-shopu panvicky.cz",
    title: "Panvicky.cz",
    link: "reference/panvicky",
  },
  {
    src: BGLDF,
    alt: "",
    logo: logoldf,
    logoAlt: "logo Lesnické a dřevařské fakulty MENDELU",
    title: "LDF MENDELU",
    link: "reference/ldf-mendelu",
  },
]
